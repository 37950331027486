import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, inject, signal } from '@angular/core';
import { GTM_EVENT_CONSTANT } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { PartnerFormModel } from '@models/partner-form.model';
import { TranslateModule } from '@ngx-translate/core';
import { CalioSafePipe } from "@pipe/calio-safe.pipe";
import { AnalyticsService } from '@services/analytics.service';
import { HttpClientService } from '@services/http-client.service';

@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
  styleUrls: ['./final-step.component.scss'],
  standalone: true,
  imports: [TranslateModule, CalioSafePipe]
})
export class FinalStepComponent implements OnInit {
  @Input() protected partner: PartnerFormModel;

  private analyticsService = inject(AnalyticsService);
  private httpClientService = inject(HttpClientService);

  protected readonly gtmEventConstant = GTM_EVENT_CONSTANT;
  protected readonly redirectDomain = environment.redirectDomain;

  protected isResendEmailSend$ = signal(false);
  protected remainingTime$ = signal(60);
  protected blockForTimeMinutes$ = signal(0);
  protected blockForTimeSeconds$ = signal(0);
  protected statusMessage$ = signal('');

  ngOnInit(): void {
    this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_3);
  }

  protected resendMail(): void {
    this.httpClientService.authServicePost(`partners/send-verification-email/${this.partner.uuid}`, {}).subscribe({
      next: result => {
        if (result?.status) {
          this.isResendEmailSend$.set(true);
          this.startCountDown();
        }
      },
      error: (error: HttpErrorResponse) => {
        const errorState = error?.error?.error;

        // User will be block, if he makes too many requests
        if (errorState === 'TooManyRequestsException') {
          if (error.headers.get('Retry-After')) {
            const retryAfter = +error.headers.get('Retry-After');
            if (retryAfter > 60) {
              this.blockForTimeMinutes$.set(Math.floor(retryAfter / 60));
            } else {
              this.blockForTimeSeconds$.set(+error.headers.get('Retry-After'));
            }
          } else {
            this.statusMessage$.set('TooManyRequestsException'.toLowerCase());
          }
          return;
        }

        this.statusMessage$.set('SomeThingWentWrong'.toLowerCase());
        if ([
          'BadRequestException',
          'IpNotWhitelistedException',
          'ResendLimitReachedException'
        ].includes(errorState)) {
          this.statusMessage$.set(errorState.toLowerCase());
        }
      }
    });
  }

  protected startCountDown(): void {
    setTimeout(() => {
      if (this.remainingTime$() < 2) {
        this.remainingTime$.set(60);
        this.isResendEmailSend$.set(false);
      } else {
        this.remainingTime$.update(time => time - 1);
        this.startCountDown();
      }
    }, 1000)
  }
}
